import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import axios from 'axios'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes: [
    {
      path: '/',
      name: 'main',
      meta: {
      },
      beforeEnter (to, from, next) {
        const token = sessionStorage.getItem('jwt-auth-token')
        if (token) {
          location.href = '/home'
        } else {
          location.href = '/login'
          store.dispatch('user/logout')
        }
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/auth/Login'),
      meta: {
        requiresAuth: false,
      },
      beforeEnter (to, from, next) {
        const token = sessionStorage.getItem('jwt-auth-token')
        if (token) {
          axios.get('/user/info', {
            headers: {
              'jwt-auth-token': token,
            },
          })
            .then(res => {
              if (res.data) {
                next('/home')
              }
            })
            .catch(() => {
              if (to.name !== 'Login') {
                next('/login')
              }
            })
        } else {
          next()
        }
      },
    },
    {
      path: '/home',
      name: 'Home',
      component: () => import('@/views/home/Index'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/auth/Login'),
      meta: {
      },
    },
    {
      path: '/notice/:pageNum',
      name: 'Notice',
      component: () => import('@/views/notice/Index'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/notice/view/:noticeIdx',
      name: 'NoticeView',
      component: () => import('@/views/notice/view/Index'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/article/:articleIdx',
      name: 'Article',
      component: () => import('@/views/board/view/Index'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/rank',
      name: 'Rank',
      component: () => import('@/views/rank/Index'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/board/:pageNum',
      name: 'Board',
      component: () => import('@/views/board/Index'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/product/detail/:productIdx',
      name: 'ProductDetail',
      component: () => import('@/views/product/view/Index'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/product/:pageNum',
      name: 'Product',
      component: () => import('@/views/product/Index'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/product/registration/:pageNum',
      name: 'ProductRegistration',
      component: () => import('@/views/product/registration/Index'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/my-page',
      name: 'MyPage',
      component: () => import('@/views/my-page/Index'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/manage',
      name: 'Manage',
      component: () => import('@/views/manage/Index'),
      meta: {
        requiresAuth: true,
        requireAdmin: true,
      },
    },
    {
      path: '/manage/users/:pageNum',
      name: 'ManageUsers',
      component: () => import('@/views/manage/user/Index'),
      meta: {
        requiresAuth: true,
        requireAdmin: true,
      },
    },
    {
      path: '/manage/history/:pageNum',
      name: 'ManagePointHistory',
      component: () => import('@/views/manage/history/Index'),
      meta: {
        requiresAuth: true,
        requireAdmin: true,
      },
    },
    {
      path: '/manage/withdraw/:pageNum',
      name: 'ManageWithdraw',
      component: () => import('@/views/manage/withdraw/Index'),
      meta: {
        requiresAuth: true,
        requireAdmin: true,
      },
    },
    {
      path: '/manage/popup/:pageNum',
      name: 'ManagePopup',
      component: () => import('@/views/manage/popup/Index'),
      meta: {
        requiresAuth: true,
        requireAdmin: true,
      },
    },
    {
      path: '/manage/admin/:pageNum',
      name: 'ManageAdmin',
      component: () => import('@/views/manage/admin/Index'),
      meta: {
        requiresAuth: true,
        requireAdmin: true,
      },
    },
    {
      path: '/manage/estimate/:pageNum',
      name: 'ManageEstimate',
      component: () => import('@/views/manage/estimate/Index'),
      meta: {
        requiresAuth: true,
        requireAdmin: true,
      },
    },
    {
      path: '/manage/estimate/detail/:estimateIdx',
      name: 'ManageEstimateDetail',
      component: () => import('@/views/manage/estimate/view/Index'),
      meta: {
        requiresAuth: true,
        requireAdmin: true,
      },
    },
    {
      path: '/manage/company/:pageNum',
      name: 'ManageCompany',
      component: () => import('@/views/manage/company/Index'),
      meta: {
        requiresAuth: true,
        requireAdmin: true,
      },
    },
    {
      path: '/manage/product/:pageNum',
      name: 'ManageProduct',
      component: () => import('@/views/manage/product/Index'),
      meta: {
        requiresAuth: true,
        requireAdmin: true,
      },
    },
    {
      path: '/manage/product/registration/:pageNum',
      name: 'ManageProductRegistration',
      component: () => import('@/views/manage/product/registration/Index'),
      meta: {
        requiresAuth: true,
        requireAdmin: true,
      },
    },
    {
      path: '/manage/site',
      name: 'ManageSite',
      component: () => import('@/views/manage/site/Index'),
      meta: {
        requiresAuth: true,
        requireAdmin: true,
      },
    },
  ],
})
router.afterEach((to, from) => {
  const title = to.meta.title === undefined ? store.state.site.site.siteTitle : to.meta.title
  Vue.nextTick(() => {
    document.title = title
  })
})
router.beforeEach((to, from, next) => {
  axios.get('/site').then(res => {
    if (res && res.data) {
      store.commit('site/setSiteInfo', res.data)
    }
  })
  if (to.matched.some(record => record.meta.requiresAuth)) {
    axios.get('/user/info', {
      headers: {
        'jwt-auth-token': sessionStorage.getItem('jwt-auth-token') ? sessionStorage.getItem('jwt-auth-token') : '',
      },
    })
      .then(res => {
        if (res.data) {
          store.dispatch('user/refresh', res.data)
        }
      })
      .catch(() => {
        store.dispatch('user/logout')
      })
    if (to.matched.some(record => record.meta.requireAdmin)) {
      const user = store.state.user.user
      if (user.userAuth === 'M' || user.userAuth === 'A') {
        next()
      } else {
        window.location.href = '/'
      }
    }
    if (!store.getters['user/isLoggedIn']) {
      next({
        name: 'Login',
        path: '/login',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
  Vue.use(require('vue-analytics').default, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    router,
    autoTracking: {
      page: process.env.NODE_ENV !== 'development',
    },
  })
}

export default router
