import router from '@/router'
export default {
  namespaced: true,
  state: {
    loggedIn: false,
    user: {},
  },

  actions: {
    login: async ({ commit }, payload) => {
      commit('setLoggedIn', payload)
    },
    logout: async ({ commit }) => {
      commit('setLoggedOut')
    },
    refresh: async ({ commit }, payload) => {
      commit('refreshUser', payload)
    },
  },

  mutations: {
    setLoggedOut (state) {
      state.user = {}
      state.loggedIn = false
      sessionStorage.removeItem('jwt-auth-token')
      localStorage.removeItem('jwt-auth-token')
      window.location.href = '/login'
    },
    refreshUser (state, payload) {
      if (payload) {
        if (payload.authToken) {
          sessionStorage.setItem('jwt-auth-token', payload.authToken)
          localStorage.setItem('jwt-auth-token', payload.authToken)
          state.user = payload
          state.loggedIn = true
        }
      }
    },
    setLoggedIn (state, payload) {
      if (payload && payload.user) {
        const user = payload.user
        if (payload.authToken) {
          sessionStorage.setItem('jwt-auth-token', payload.authToken)
          localStorage.setItem('jwt-auth-token', payload.authToken)
          state.user = user
          state.loggedIn = true
          router.go()
        }
      }
    },
  },
  getters: {
    isLoggedIn: state => state.loggedIn,
  },
}
