import moment from 'moment-timezone'
export default {
  install (Vue) {
    Vue.prototype.$parseDate = function (date) {
      return moment(date).tz('Asia/Seoul').format('YYYY-MM-DD')
    }
    Vue.prototype.$parseDateMinute = function (date) {
      return moment(date).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm')
    }
    Vue.prototype.$parseDateSecond = function (date) {
      return moment(date).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')
    }
    Vue.prototype.$parseHours = function (date) {
      return moment(date).tz('Asia/Seoul').format('HH:mm:ss')
    }
    Vue.prototype.$parseDateKo = function (date) {
      return moment(date).tz('Asia/Seoul').format('YYYY년 M월 D일')
    }
    Vue.prototype.$parseArticleTitleDate = function (date) {
      return moment(date).tz('Asia/Seoul').format('MMDD')
    }
  },
}
