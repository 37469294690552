import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import app from '@/store/modules/app'
import auth from '@/store/modules/auth'
import user from '@/store/modules/user'
import downloads from '@/store/modules/downloads'
import friends from '@/store/modules/friends'
import games from '@/store/modules/games'
import home from '@/store/modules/home'
import install from '@/store/modules/install'
import library from '@/store/modules/library'
import snackbar from '@/store/modules/snackbar'
import verify from '@/store/modules/verify'
import site from '@/store/modules/site'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    auth,
    downloads,
    friends,
    games,
    home,
    install,
    library,
    snackbar,
    verify,
    user,
    site,
  },
  actions: {
    init: async () => {
      await Promise.all([])
    },
  },
  plugins: [
    createPersistedState({
      paths: ['user'],
    }),
  ],
})

store.dispatch('init')

export default store
