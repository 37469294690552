import Vue from 'vue'
import BasePlugins from './plugins/base'
import axios from 'axios'
import MobileCheck from './plugins/mobile-check'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import vueMoment from 'vue-moment/dist/vue-moment'
import VueCookies from 'vue-cookies'
import moment from 'moment/moment'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import Meta from 'vue-meta'
import { sync } from 'vuex-router-sync'
import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true
moment.locale('ko')
Vue.use(MobileCheck)
Vue.use(BasePlugins)
Vue.use(VueCookies)
Vue.use(VueClipboard)
Vue.use(Viewer)
Vue.$cookies.config('7d')
Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
})
console.log(process.env)
let apiUrl = ''
if (!process.env || !process.env.VUE_APP_MODE || process.env.VUE_APP_MODE === 'production') {
  apiUrl = 'http://api.saycompany.co.kr/api/'
} else if (process.env.VUE_APP_MODE === 'local') {
  apiUrl = 'http://localhost:9503/api/'
}
Vue.prototype.api = apiUrl
axios.defaults.baseURL = apiUrl
if (!sessionStorage.getItem('jwt-auth-token') && localStorage.getItem('jwt-auth-token')) {
  sessionStorage.setItem('jwt-auth-token', localStorage.getItem('jwt-auth-token'))
}
axios.defaults.headers.common['jwt-auth-token'] = sessionStorage.getItem('jwt-auth-token') ? sessionStorage.getItem('jwt-auth-token') : (localStorage.getItem('jwt-auth-token') ? localStorage.getItem('jwt-auth-token') : store.state.user.authToken)
Vue.prototype.$axios = axios
Vue.use(vueMoment, { moment })
sync(store, router)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
