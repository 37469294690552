<template>
  <div class="pa-3">
    <v-card
      v-if="popup && popup.show && mobile"
      elevation="5"
      :dark="$vuetify.theme.dark"
      :light="!$vuetify.theme.dark"
      max-height="100%"
      outlined
      :style="{
        overflowY: 'auto'
      }"
    >
      <v-card-title class="text-caption pt-1 pb-2">
        {{ popup.title }}
      </v-card-title>
      <v-card-text
        class="popup-content"
        v-html="popup.content"
      />
      <v-card-actions>
        <v-btn
          small
          text
          @click="doNotShowToday"
        >
          7일간 보지않음
        </v-btn>
        <v-spacer />
        <v-btn
          small
          text
          @click="close"
        >
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
    <DraggableDiv
      v-if="popup && popup.show && !mobile"
      :id="'popup-' + popup.idx"
    >
      <template slot="main">
        <v-card
          elevation="5"
          :dark="$vuetify.theme.dark"
          :light="!$vuetify.theme.dark"
        >
          <v-card-title class="text-caption pt-1 pb-2">
            {{ popup.title }}
          </v-card-title>
          <v-card-text
            class="popup-content"
            :style="{
              width: popup.width + 'px',
              height: popup.height + 'px',
            }"
            v-html="popup.content"
          />
          <v-card-actions>
            <v-btn
              small
              text
              @click="doNotShowToday"
            >
              7일간 보지않음
            </v-btn>
            <v-spacer />
            <v-btn
              small
              text
              @click="close"
            >
              닫기
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </DraggableDiv>
  </div>
</template>

<script>
  import DraggableDiv from '@/components/utils/DraggableDiv'
  export default {
    name: 'Popup',
    components: {
      DraggableDiv,
    },
    props: [
      'popup',
    ],
    computed: {
      mobile () {
        return this.$isMobile
      },
    },
    beforeMount () {
      this.show()
    },
    methods: {
      show () {
        const disabled = this.$cookies.get('popup-' + this.popup.idx)
        if (!disabled) {
          this.$emit('show-popup')
        }
      },
      doNotShowToday () {
        const keyName = 'popup-' + this.popup.idx
        const disabled = true
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        tomorrow.setHours(0)
        tomorrow.setMinutes(0)
        tomorrow.setSeconds(0)
        tomorrow.setMilliseconds(0)
        this.$cookies.set(keyName, disabled, '7d')
        this.close()
      },
      close () {
        this.$emit('close-popup')
      },
    },
  }
</script>

<style scoped lang="scss">
.popup-content {
  overflow: auto;
}
.popup-content::v-deep {
  img {
    border-radius: 5px;
    max-width: 100%;
  }
}
</style>
