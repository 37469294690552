export default {
  namespaced: true,

  state: {
    notices: [
    ],
    articles: [
    ],
  },
}
