var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{style:(_vm.textColor)},[_vm._l((_vm.popups),function(popup){return _c('Popup',{key:'popup-' + popup.idx,staticClass:"popup",style:({
      zIndex: (1000 + popup.idx),
      left: _vm.mobile ? 'initial' : (popup.positionY + 'px'),
      top: _vm.mobile ? 'initial' : (popup.positionX + 'px'),
    }),attrs:{"popup":popup},on:{"close-popup":function($event){popup.show = false},"show-popup":function($event){popup.show = true}}})}),_vm._l((_vm.reviews),function(review){return _c('ReviewPopup',{key:'review-popup-' + review.idx,staticClass:"popup",style:({
      width: _vm.mobile ? '100%' : '',
      zIndex: (1000 + review.idx),
      right: _vm.mobile ? 'initial' : '300px',
      top: _vm.mobile ? 'initial' : '0px',
    }),attrs:{"review":review},on:{"close-popup":function($event){review.show = false},"show-popup":function($event){review.show = true}}})}),(_vm.$store.state.user.loggedIn)?[_c('core-app-bar'),_c('core-drawer')]:_vm._e(),_c('core-view'),(_vm.$store.state.user.loggedIn)?[_c('core-user-info'),_c('core-snackbar')]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }