<template>
  <div class="pa-3">
    <v-card
      v-if="review && review.show && mobile"
      elevation="5"
      :dark="$vuetify.theme.dark"
      :light="!$vuetify.theme.dark"
      max-height="100%"
      width="100%"
      outlined
      :style="{
        overflowY: 'auto'
      }"
    >
      <v-card-text
        class="popup-content"
      >
        {{ `${review.purchaseName}님 ${review.purchaseDate} ${review.productName}(${review.companyName})` }}<br>
        한달 리뷰 작성해주세요.
        <br><br>
        1. 한달리뷰 버튼 생성 후 작성!
        <br>
        2. 리뷰캡쳐 이미지 올릴 때 한달사용기라는 내용이 있어야합니다.
      </v-card-text>
      <v-card-actions>
        <v-btn
          small
          text
          @click="doNotShowToday"
        >
          7일간 보지않음
        </v-btn>
        <v-spacer />
        <v-btn
          small
          text
          @click="close"
        >
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
    <DraggableDiv
      v-if="review && review.show && !mobile"
      :id="'review-popup-' + review.idx"
    >
      <template slot="main">
        <v-card
          elevation="5"
          :width="300"
          :dark="$vuetify.theme.dark"
          :light="!$vuetify.theme.dark"
        >
          <v-card-text
            class="popup-content"
          >
            {{ `${review.purchaseName}님 ${review.purchaseDate} ${review.productName}(${review.companyName})` }}<br>
            한달 리뷰 작성해주세요.
            <br><br>
            1. 한달리뷰 버튼 생성 후 작성!
            <br>
            2. 리뷰캡쳐 이미지 올릴 때 한달사용기라는 내용이 있어야합니다.
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="review.articleIdx"
              small
              text
              @click="$router.push(`/article/${review.articleIdx}`)"
            >
              게시글 바로가기
            </v-btn>
            <v-spacer />
            <v-btn
              small
              text
              @click="close"
            >
              닫기
            </v-btn>
            <v-btn
              small
              text
              @click="doNotShowToday"
            >
              7일간 보지않음
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </DraggableDiv>
  </div>
</template>

<script>
  import DraggableDiv from '@/components/utils/DraggableDiv'
  export default {
    name: 'ReviewPopup',
    components: {
      DraggableDiv,
    },
    props: [
      'review',
    ],
    computed: {
      mobile () {
        return this.$isMobile
      },
    },
    beforeMount () {
      this.show()
    },
    methods: {
      show () {
        const disabled = this.$cookies.get('review-popup-' + this.review.idx)
        if (!disabled) {
          this.$emit('show-popup')
        }
      },
      doNotShowToday () {
        const keyName = 'review-popup-' + this.review.idx
        const disabled = true
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        tomorrow.setHours(0)
        tomorrow.setMinutes(0)
        tomorrow.setSeconds(0)
        tomorrow.setMilliseconds(0)
        this.$cookies.set(keyName, disabled, '7d')
        this.close()
      },
      close () {
        this.$emit('close-popup')
      },
    },
  }
</script>

<style scoped lang="scss">
.popup-content::v-deep {
  img {
    border-radius: 5px;
    max-width: 100%;
  }
}
</style>
