<template>
  <v-app :style="textColor" style>
    <Popup
      v-for="popup in popups"
      :key="'popup-' + popup.idx"
      :popup="popup"
      class="popup"
      :style="{
        zIndex: (1000 + popup.idx),
        left: mobile ? 'initial' : (popup.positionY + 'px'),
        top: mobile ? 'initial' : (popup.positionX + 'px'),
      }"
      @close-popup="popup.show = false"
      @show-popup="popup.show = true"
    />
    <ReviewPopup
      v-for="review in reviews"
      :key="'review-popup-' + review.idx"
      :review="review"
      class="popup"
      :style="{
        width: mobile ? '100%' : '',
        zIndex: (1000 + review.idx),
        right: mobile ? 'initial' : '300px',
        top: mobile ? 'initial' : '0px',
      }"
      @close-popup="review.show = false"
      @show-popup="review.show = true"
    />
    <template v-if="$store.state.user.loggedIn">
      <core-app-bar />
      <core-drawer />
    </template>
    <core-view />
    <template v-if="$store.state.user.loggedIn">
      <core-user-info />
      <core-snackbar />
    </template>
  </v-app>
</template>

<script>
  import Popup from '@/components/core/Popup'
  import ReviewPopup from '@/components/core/ReviewPopup.vue'
  export default {
    components: {
      ReviewPopup,
      Popup,
      CoreAppBar: () => import('@/components/core/AppBar'),
      CoreDrawer: () => import('@/components/core/Drawer'),
      CoreUserInfo: () => import('@/components/core/UserInfo'),
      CoreSnackbar: () => import('@/components/core/Snackbar'),
      CoreView: () => import('@/components/core/View'),
    },
    data () {
      return {
        popups: [],
        siteInfo: {},
        reviews: [],
      }
    },
    computed: {
      mobile () {
        return this.$isMobile
      },
      textColor () {
        return {
          '--text-color': this.$vuetify.theme.dark ? 'white' : '#000000de',
        }
      },
    },
    beforeMount () {
      this.getPopups()
      this.getMonthReviewPopups()
    },
    methods: {
      logout () {
        this.$store.dispatch('user/logout')
      },
      async getPopups () {
        const url = '/popup/popups'
        await this.$axios.get(url).then(res => {
          if (res && res.data) {
            this.popups = res.data
          }
        }).catch(e => {
          // ignore
        })
      },
      async getMonthReviewPopups () {
        const url = '/popup/month'
        await this.$axios.get(url).then(res => {
          if (res && res.data) {
            this.reviews = res.data
          }
        }).catch(e => {
          // ignore
        })
      },
      generateStyle (popup) {
        return {
          position: 'fixed',
          left: popup.positionX + 'px',
          top: popup.positionY + 'px',
          zIndex: popup.idx + 100,
        }
      },
      closePopup (popup) {
        popup.show = false
      },
    },
  }
</script>

<style lang="scss">
body {
  overscroll-behavior-y: none;
}
.v-card {
  border-radius: 6px;
}
a {
  text-decoration: none;
}
.popup {
  position: fixed;
}
.theme--light::v-deep {
  a {
    color: #1E1E1E
  }
}
.no-message {
  .v-text-field__details {
    display: none !important;
  }
}
.flex-action {
  display: flex;
  flex-wrap: wrap;
}
.rounded-1 {
  border-radius: .1rem !important;
}
.rounded-2 {
  border-radius: .2rem !important;
}
.rounded-3 {
  border-radius: .3rem !important;
}
.rounded-4 {
  border-radius: .4rem !important;
}
.rounded-5 {
  border-radius: .5rem !important;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-35 {
  width: 35%;
}
.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}
.w-50 {
  width: 50%;
}
.w-55 {
  width: 55%;
}
.w-60 {
  width: 60%;
}
.w-65 {
  width: 65%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.w-85 {
  width: 85%;
}
.w-90 {
  width: 90%;
}
.w-95 {
  width: 95%;
}
.w-100 {
  width: 100%;
}
.h-10 {
  height: 10%;
}
.h-15 {
  height: 15%;
}
.h-20 {
  height: 20%;
}
.h-25 {
  height: 25%;
}
.h-30 {
  height: 30%;
}
.h-35 {
  height: 35%;
}
.h-40 {
  height: 40%;
}
.h-45 {
  height: 45%;
}
.h-50 {
  height: 50%;
}
.h-55 {
  height: 55%;
}
.h-60 {
  height: 60%;
}
.h-65 {
  height: 65%;
}
.h-70 {
  height: 70%;
}
.h-75 {
  height: 75%;
}
.h-80 {
  height: 80%;
}
.h-85 {
  height: 85%;
}
.h-90 {
  height: 90%;
}
.h-95 {
  height: 95%;
}
.h-100 {
  height: 100%;
}
.v-application td a {
  color: var(--text-color);
}
</style>
