
export default {
  namespaced: true,

  state: {
    site: {
      siteName: '',
      siteTitle: '',
    },
  },
  mutations: {
    setSiteInfo (state, payload) {
      if (payload) {
        state.site = payload
      }
    },
  },
}
