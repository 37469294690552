export default {
  install (Vue) {
    Vue.prototype.$isMobile = func.isMobile()
  },
}

export const func = {
  isMobile () {
    let isMobile = false
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      isMobile = true
    }
    return isMobile
  },
}
